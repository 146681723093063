import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ResourceListing from '../components/ResourceListing';
import ModularBlocks from '../components/ModularBlocks';
import { getCollectionLocaleFields } from '../utils';

const BlogArchiveTemplate = ({
  data: { datoCmsBlogArchive, allDatoCmsBlog },
  pageContext: { locale },
}) => {
  const { seoMetaTags, slug, slugLocales, title, banner, modularBlocks } =
    datoCmsBlogArchive;
  const { nodes } = getCollectionLocaleFields(allDatoCmsBlog, locale);

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsBlogArchive}
    >
      <main>
        <Banner
          heading={title}
          text={banner.text}
          link={banner.link}
          image={banner.image}
        />
        <ResourceListing
          heading={title}
          items={nodes}
          archiveSlug={slug}
          locale={locale}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const BlogArchiveTemplateQuery = graphql`
  query BlogArchiveTemplateQuery($id: String!) {
    datoCmsBlogArchive(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      banner {
        ...BannerFragment
      }
      modularBlocks {
        ...ContentModularBlockFragment
      }
    }
    allDatoCmsBlog(
      sort: {
        fields: [publishedDate, meta___firstPublishedAt]
        order: [DESC, DESC]
      }
    ) {
      group(field: locale) {
        fieldValue
        nodes {
          ...BlogResourceCardFragment
        }
      }
    }
  }
`;

export default BlogArchiveTemplate;
